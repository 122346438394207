import validate from './validate';
import passwordMatch from './passwordMatch';
import inputStates from './inputStates';

export { instanceMethods } from './validate';

export default (formClass, options = {}) => {
  /*
    Takes a function called inputName that should return a string
    this is useful if your form is using things like `custom_form_entry[1]` and you need
    it to return `first_name` for you validation key
    it takes an HTML Input Element
    could be something like
    const opts = {
      inputName: input => input.id.split('custom_form_entry_')[1]
    };
  */
  const loadHandler = () => {
    const selectedClass = formClass || '.js-form';
    document.querySelectorAll(selectedClass).forEach((element) => {
      validate(element, options);
    });
    inputStates();
    passwordMatch();
  };

  if (document.readyState === 'loading') {
    // loading hasn't finished yet so it is ok to listen for domcontentloaded
    document.addEventListener('DOMContentLoaded', loadHandler);
  } else {
    loadHandler();
  }
};
