import {
  DIRTY_CLASS,
  VALIDATION_ERROR_CLASS,
  INPUT_INVALID_CLASS,
  VALID_CLASS,
  DISABLED_SUBMIT_INPUT,
} from './constants';

const formVersion = document.querySelector('.simple_form')?.dataset?.formVersion;
const requiredNeeded = document.querySelector('.simple_form')?.dataset?.requiredNeeded;

const emptyInputValue = (input) => {
  if (input.type === 'checkbox') return true;
  if (input.type === 'radio') return true;

  return input.value === '';
};

const noneSelected = siblings => !siblings.some(sibling => sibling.classList.contains('form-check') && sibling.firstChild.checked);

export const isValidatable = (input) => {
  const classes = input.classList;

  return Array.prototype.indexOf.call(classes, DIRTY_CLASS) > -1
    || Array.prototype.indexOf.call(classes, 'form__input--invalid') > -1;
};

export const findOrCreateErrorNode = ({
  errorMessage,
  input,
  cb,
}) => {
  if (isValidatable(input)) {
    let parentNode;
    if (cb) {
      parentNode = cb(input);
    } else {
      const { parentNode: { parentNode: parent } } = input;
      parentNode = parent;
    }

    const errorNode = parentNode.querySelector(`.${VALIDATION_ERROR_CLASS}`) || document.createElement('div');
    errorNode.classList.add(VALIDATION_ERROR_CLASS);
    if (errorMessage) {
      errorNode.textContent = errorMessage;
    } else {
      errorNode.textContent = input.validationMessage;
    }

    if (emptyInputValue(input) && formVersion === '2' && requiredNeeded !== 'true') {
      input.classList.remove(INPUT_INVALID_CLASS);
      input.classList.remove(DIRTY_CLASS);

      if (parentNode.querySelector(`.${VALIDATION_ERROR_CLASS}`)) parentNode.removeChild(errorNode);
    } else {
      const siblings = [...parentNode.children];
      if (input.type === 'checkbox') {
        if (noneSelected(siblings)) {
          siblings
            .filter(sibling => sibling.classList.contains('form-check'))
            .map(sibling => sibling.firstChild.classList.add(INPUT_INVALID_CLASS));
          parentNode.appendChild(errorNode);
        }
      } else {
        input.classList.remove(VALID_CLASS);
        input.classList.add(INPUT_INVALID_CLASS);
        parentNode.appendChild(errorNode);
      }
    }
  }
};

export const removeInvalidClass = function (sibling) {
  if (sibling.firstChild.classList) {
    sibling.firstChild.classList.remove(INPUT_INVALID_CLASS);
  }
};

export const removeErrorNode = (input, cb) => {
  let parentNode;
  if (cb) {
    parentNode = cb(input);
  } else {
    const { parentNode: { parentNode: parent } } = input;
    parentNode = parent;
  }

  const errorNode = parentNode.querySelector(`.${VALIDATION_ERROR_CLASS}`);
  input.classList.remove(INPUT_INVALID_CLASS);
  if (errorNode && parentNode === errorNode.parentNode) {
    parentNode.removeChild(errorNode);
  }
  if (input.type === 'checkbox') {
    const siblings = [...parentNode.children];
    siblings
      .filter(sibling => sibling.classList.contains('form-check'))
      .map(removeInvalidClass);
  }
};

export const toggleSubmitButton = function (...args) {
  let form;
  if (args[0] && args[0].nodeName && args[0].nodeName.toLowerCase() === 'form') {
    [form] = args;
  } else {
    form = args[0].currentTarget;
  }

  const submit = form.querySelector('input[type="submit"]');

  form.checkValidity();
  if (form.checkValidity()) {
    submit.classList.remove(DISABLED_SUBMIT_INPUT);
    submit.disabled = false;
    return;
  }
  submit.classList.add(DISABLED_SUBMIT_INPUT);
  submit.disabled = true;
};

export const makeDirty = (input) => {
  input.classList.add(DIRTY_CLASS);
};

export default {
  isValidatable,
  findOrCreateErrorNode,
  makeDirty,
  removeErrorNode,
};
