const formVersion = document.querySelector('.simple_form')?.dataset?.formVersion;

const activate = (input) => {
  input.classList.add('filled');
};

const deactivate = (input) => {
  if (input.value === '') input.classList.remove('filled');
};

const initializeInputState = (input) => {
  if (formVersion === '2') return;
  if (input.value !== '') activate(input);

  input.addEventListener('focus', () => { activate(input); });
  input.addEventListener('blur', () => { deactivate(input); });
};

const disconnectInputStates = (inputs) => {
  inputs.forEach((input) => {
    input.removeEventListener('focus', () => { activate(input); });
    input.removeEventListener('blur', () => { deactivate(input); });
  });
};

export default () => {
  const inputs = Array.prototype.slice.call(document.querySelectorAll('.form__input'));

  inputs.forEach((input) => {
    initializeInputState(input);
  });

  return () => { disconnectInputStates(inputs); };
};
