import {
  findOrCreateErrorNode,
  isValidatable,
  makeDirty,
  removeErrorNode,
} from './domUtil';
import {
  INPUT_INVALID_CLASS,
  CUSTOM_DATE_CLASS,
  INPUT_SKIP_VALIDATIONS,
} from './constants';
import i18n from '../i18n.js.erb';
import camelCaseToSnakeCase from '../utils/camelCaseToSnakeCase';

const validateOptions = {};

const errorProps = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing',
  'customError',
];

const { lang } = document.documentElement;

const customDateInputExists = (input) => {
  const classes = input.classList;
  return Array.prototype.indexOf.call(classes, CUSTOM_DATE_CLASS) > -1;
};

const invalidWordLimit = (input) => {
  const dataWordLimitAttr = input.getAttribute('data-word-limit');
  return dataWordLimitAttr
      && input.value.trim().split(/\s+/).length
      > parseInt(dataWordLimitAttr, 10);
};

const CustomMessageWordLimit = (input) => {
  const dataWordLimitAttr = input.getAttribute('data-word-limit');
  return i18n[lang].word_limit.replace('%{count}', dataWordLimitAttr, 'gi');
};

const errorMessage = (modelName, attribute, messageKey) => {
  let messages = i18n[lang].models[modelName];
  if (!messages) { return null; }

  messages = messages.attributes[attribute];
  if (!messages) { return null; }

  return messages[messageKey];
};

const inputName = (input) => {
  const name = input.name.match(/(\[.+\])/);
  if (!name) { return input.name; }

  return name[0].replace(/\[|\]/g, '');
};

const getCustomMessage = function (input) {
  if (invalidWordLimit(input)) {
    return CustomMessageWordLimit(input);
  }
  const { modelName } = input.form.dataset;
  const { validity } = input;
  const attribute = validateOptions.inputName(input);
  const localErrorProps = [`${input.type}Mismatch`].concat(errorProps);

  for (let i = 0; i < localErrorProps.length; i += 1) {
    const prop = localErrorProps[i];
    const snakeCasedProp = camelCaseToSnakeCase(prop);
    if (validity[prop]) {
      return validateOptions.errorMessage(modelName, attribute, snakeCasedProp)
        || i18n[lang][snakeCasedProp];
    }
  }
  return true;
};

const handleCustomMessages = (input) => {
  const checkValidity = () => {
    const message = !input.validity.valid || invalidWordLimit(input)
      ? getCustomMessage(input)
      : null;
    input.setCustomValidity(message || '');
  };

  input.addEventListener('input', checkValidity);
  input.addEventListener('blur', checkValidity);
  input.addEventListener('invalid', checkValidity);
};

const handleCustomMessageDisplay = (input) => {
  const checkValidity = () => {
    if (!input.validity.valid && input.validationMessage) {
      if (customDateInputExists(input)) {
        input.setCustomValidity(input.title);
      }
      findOrCreateErrorNode({
        input,
        cb: validateOptions.findParentNode,
      });
    } else {
      removeErrorNode(input, validateOptions.findParentNode);
    }
  };

  input.addEventListener('input', () => {
    if (isValidatable(input)) {
      checkValidity();
    }
  });

  input.addEventListener('blur', () => {
    if (!isValidatable(input)) {
      makeDirty(input);
    }
    checkValidity();
  });

  input.addEventListener('invalid', (e) => {
    e.preventDefault();
    checkValidity();
  });
};

const setFormValidation = (inputs, options) => {
  const { submit } = options;

  /*
    Since we want to make sure that the user gets feedback on all the fields that
    are invalid when clicking on the submit button, especially in the case where
    they may not have blurred out of the field we need to make the fields dirty
    on click of the submit button.
    This is because we only start validating if the field is considered dirty, i.e.,
    the user has entered the field and either and exited or entered input and exited.
    This is to prevent validations from happening when the user has first entered
    the input and started typing. That would be annoying.
  */
  if (submit) {
    submit.addEventListener('click', () => {
      inputs.forEach((input) => {
        if (input.classList.contains(INPUT_SKIP_VALIDATIONS)) { return; }
        makeDirty(input);
      });
    });
  }

  inputs.forEach((input, type) => {
    if (input.classList.contains(INPUT_SKIP_VALIDATIONS)) { return; }
    if (input.classList.contains(INPUT_INVALID_CLASS)) { makeDirty(input); }
    handleCustomMessages(input, type);
    handleCustomMessageDisplay(input, options);
  });
};

export const instanceMethods = {
  findOrCreateErrorNode,
};

export default (form, options) => {
  const type = form.nodeName.toLowerCase();
  validateOptions.inputName = options.inputName || inputName;
  validateOptions.errorMessage = options.errorMessage || errorMessage;
  validateOptions.validations = options.validations;
  validateOptions.findParentNode = options.findParentNode;

  if (!form || !form.nodeName) {
    throw new Error('First arg must be a form');
  }

  if (type !== 'form') {
    throw new Error('Only forms are supported');
  }

  const inputs = form.querySelectorAll('input, select, textarea');
  const submit = form.querySelector('input[type="submit"]:not([formnovalidate])') || form.querySelector('.js-credit-card-submit-btn');

  setFormValidation(inputs, { form, submit });
  return {
    findOrCreateErrorNode,
  };
};
